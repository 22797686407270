import * as React from "react";
import { observer } from "mobx-react";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { withStores } from "src/stores";
import { Slider } from "../components/Slider";
import { PropertyFiltersComponentGroupProps } from "../typings";

export const SliderGroup = withStores("propertyFilters")(
  observer((props: PropertyFiltersComponentGroupProps) => {
    const { filterLabel, filterName, options, moduleName, min, max, step, subLabel } = props;

    return (
      <EGDSSpacing margin={{ inline: "four" }}>
        <div role="group" aria-label={filterLabel}>
          {options.map((option, index) => (
            <Slider
              min={min}
              max={max}
              step={step}
              subLabel={subLabel}
              key={`${option.optionValue}-${index}`}
              option={option}
              filterName={filterName}
              moduleName={moduleName}
            />
          ))}
        </div>
      </EGDSSpacing>
    );
  })
);

export default SliderGroup;
