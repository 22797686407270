import * as React from "react";
import { PropertyFiltersComponentProps } from "../typings";
import { EGDSRadioButton } from "@egds/react-core/radio-button";
import { withStores } from "src/stores";
import { observer } from "mobx-react";
import { EGDSSpacing } from "@egds/react-core/spacing";

export const Radio = withStores("propertyFilters")(
  observer((props: PropertyFiltersComponentProps) => {
    const { filterName, option, propertyFilters } = props;
    const { optionValue, description, label } = option;

    const isSelected = propertyFilters.isSelected(filterName, optionValue ?? "");

    return (
      <EGDSSpacing margin="half">
        <EGDSRadioButton
          id={`${filterName}-${optionValue}`}
          value={optionValue ?? ""}
          checked={isSelected}
          description={description ?? ""}
        >
          {label}
        </EGDSRadioButton>
      </EGDSSpacing>
    );
  })
);
