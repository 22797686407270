import { useLocalization } from "@shared-ui/localization-context";

import { JumplinkablePageType } from "stores/DXStore";
import { JumplinkItem, JumplinksTemplateComponentConfig } from "./typings";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";

export enum ModuleType {
  DESTINATION_DESCRIPTION_MODULE = "destination-description",
  DESTINATION_VIDEO_MODULE = "destination-video",
  TE_NEIGHBORHOOD_MODULE = "te-neighborhood",
  HOTELS_MODULE = "hotels",
  DESTINATION_ATTRACTIONS_MODULE = "destination-attractions",
  DESTINATION_USER_REVIEWS_MODULE = "destination-user-reviews",
  TE_ARTICLE_MODULE = "te-article",
  TE_SIMILAR_DESTINATION_MODULE = "te-similar-destination",
  ACTIVITIES_BY_POINT_OF_INTEREST = "activities-by-point-of-interest",
  ACTIVITIES_BY_CATEGORIES = "activities-by-category",
  ACTIVITY_CATEGORIES = "category-activities",
  PACKAGING = "packaging",
  FAQ = "frequently-asked-questions",
  FAQ_V2 = "frequently-asked-questions-v2",
  EDITORIAL = "editorial",
  CUSTOMERNOTIFICATIONS = "customer-notifications",
  TABGROUP = "tab-group",
  WIZARD_PACKAGE_PWA = "wizard-package-pwa",
}

// TODO: clean up when localized jumplink names are available from flex modules
export const getJumplinkLocalizedNames = (jumplink: JumplinkItem) => {
  const { pageType, moduleName, label, title, maxJumpLinkLabelLength } = jumplink;

  if (pageType === JumplinkablePageType.DX_WHERE_TO_STAY) {
    return moduleName;
  }
  // use title for editorial if defined
  if (title && (moduleName === ModuleType.EDITORIAL || moduleName === ModuleType.TABGROUP)) {
    return maxJumpLinkLabelLength ? title?.substring(0, maxJumpLinkLabelLength) : title;
  }
  const { formatText } = useLocalization();

  switch (moduleName) {
    case ModuleType.DESTINATION_DESCRIPTION_MODULE:
      return label || formatText("jumplinks.overview");
    case ModuleType.DESTINATION_VIDEO_MODULE:
      return label || formatText("jumplinks.video");
    case ModuleType.TE_NEIGHBORHOOD_MODULE:
      return label || formatText("jumplinks.whereToStay");
    case ModuleType.HOTELS_MODULE:
      return label || formatText("jumplinks.hotels");
    case ModuleType.DESTINATION_ATTRACTIONS_MODULE:
      return label || formatText("jumplinks.thingsToDo");
    case ModuleType.DESTINATION_USER_REVIEWS_MODULE:
      return label || formatText("jumplinks.reviews");
    case ModuleType.TE_ARTICLE_MODULE:
      return label || formatText("jumplinks.blogs");
    case ModuleType.TE_SIMILAR_DESTINATION_MODULE:
      return label || formatText("jumplinks.similarDestinations");
    case ModuleType.ACTIVITIES_BY_POINT_OF_INTEREST:
      return label || formatText("jumplinks.topPlaces");
    case ModuleType.ACTIVITIES_BY_CATEGORIES:
      return label || formatText("jumplinks.popularActivities");
    case ModuleType.ACTIVITY_CATEGORIES:
      return label || formatText("jumplinks.activityTypes");
    case ModuleType.PACKAGING:
      return label || formatText("jumplinks.packages");
    case ModuleType.FAQ:
    case ModuleType.FAQ_V2:
      return label || formatText("jumplinks.faq");
    case ModuleType.CUSTOMERNOTIFICATIONS:
      return label || formatText("jumplinks.customernotifications");
    case ModuleType.WIZARD_PACKAGE_PWA:
      return label || formatText("jumplinks.wppwa");
    // there can be multiple editorials and tab groups in a page.
    // This is a fall back when title is not defined for these module types
    case ModuleType.EDITORIAL:
    case ModuleType.TABGROUP:
      return label;
    default:
      throw new Error(`Jumplinkable module is undefined: ${moduleName}`);
  }
};

export const getJumplinkTitleString = (config: JumplinksTemplateComponentConfig, context: ExtendedContextStore) => {
  const { title } = config;
  const { location } = context.searchContext;
  const { formatText } = useLocalization();
  const locationName = location.localizedName || location.defaultName || location.name;

  return title || formatText("jumplinks.discover", locationName);
};
