import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "src/stores";
import { Checkbox } from "../components/Checkbox";
import { EGDSCheckboxGroup } from "@egds/react-core/checkbox";
import { PropertyFiltersComponentGroupProps } from "../typings";

export const CheckboxGroup = withStores("propertyFilters")(
  observer((props: PropertyFiltersComponentGroupProps) => {
    const { filterLabel, filterName, options, moduleName } = props;

    return (
      <EGDSCheckboxGroup legendLabel={filterLabel} group={filterName}>
        {options.map((option, index) => (
          <Checkbox
            key={`${option.optionValue}-${index}`}
            option={option}
            filterName={filterName}
            moduleName={moduleName}
          />
        ))}
      </EGDSCheckboxGroup>
    );
  })
);

export default CheckboxGroup;
