import * as React from "react";
import { withStores } from "stores";
import { observer } from "mobx-react";
import { EGDSSelect } from "@egds/react-core/input";
import { SelectGroupProps } from "../typings";
import {
  Action,
  FlexTrackingInfo,
  sendDelayedClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";

export const SelectGroup = withStores("propertyFilters")(
  observer((props: SelectGroupProps) => {
    const { propertyFilters, filterName, moduleName, filterLabel } = props;

    const selectFilterOptions = propertyFilters.getOptions(filterName);
    const selectedFilter = selectFilterOptions.find((option) => option.isSelected);
    const currentValue = selectedFilter?.optionValue || "";

    if (!selectFilterOptions || selectFilterOptions.length === 0) {
      return null;
    }

    const track = useClickTracker();

    const onChangeOption = (selectEvent: React.ChangeEvent<HTMLSelectElement>) => {
      const value = selectEvent.target.value;
      propertyFilters.toggleSelection(filterName, value);

      const flexTracking: FlexTrackingInfo = {
        moduleName,
        rfrr: `${filterName}.SELECTION.${value}`,
        action: Action.CLICK,
      };
      sendDelayedClickstreamTrackEvent(flexTracking, track);
    };

    const onClickSelect = () => {
      const flexTracking: FlexTrackingInfo = {
        moduleName,
        rfrr: `${filterName}.CURRENT.${currentValue}`,
        action: Action.CLICK,
      };
      sendDelayedClickstreamTrackEvent(flexTracking, track);
    };

    return (
      <div>
        <EGDSSelect
          id={`${moduleName}-${filterName}-selectgroup`}
          label={filterLabel}
          name={filterName}
          onChange={onChangeOption}
          onClick={onClickSelect}
          value={Boolean(currentValue) ? currentValue : undefined}
          defaultValue={Boolean(currentValue) ? currentValue : undefined}
          data-testid="select-sort-filter"
        >
          {selectFilterOptions.map((option) => (
            <option key={`${moduleName}-${filterName}-selectgroup-${option.label}`} value={option.optionValue!}>
              {option.label}
            </option>
          ))}
        </EGDSSelect>
      </div>
    );
  })
);
