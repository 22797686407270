import * as React from "react";
import { PropertyFiltersComponentProps } from "../typings";
import { FlexClickTracker } from "components/utility/analytics/FlexClickTracker/FlexClickTracker";
import { EGDSButtonToggle } from "@egds/react-core/button-toggle";
import { withStores } from "src/stores";
import { observer } from "mobx-react";
import { EGDSSpacing } from "@egds/react-core/spacing";

const ButtonToggleTracked = FlexClickTracker(EGDSButtonToggle);

export const ButtonToggle = withStores("propertyFilters")(
  observer((props: PropertyFiltersComponentProps) => {
    const { filterName, option, propertyFilters, moduleName, isSelected } = props;

    const onChange = () => {
      propertyFilters.toggleSelection(filterName, option.optionValue!);
    };

    return (
      <EGDSSpacing margin={{ blockend: "two" }}>
        <ButtonToggleTracked
          id={`${filterName}-${option.optionValue}`}
          labelText={option.label}
          altText={option.selectedLabel}
          moduleName={moduleName}
          rfrr={`${moduleName}.${filterName}.${option.optionValue}.${!isSelected}`}
          value={option.optionValue}
          checked={isSelected}
          onChange={onChange}
          data-testid={option.selectedLabel}
        />
      </EGDSSpacing>
    );
  })
);
