module.exports = {
  BlossomComponents: {
    minChunks: 2,
    minSize: 6000,
    name(module) {
      const generateName = (folder) => {
        const s = module.userRequest.split("/");
        const index = s.indexOf(folder);

        return s.slice(index, index + 2).join("~");
      };

      if (module.userRequest.includes("components/shared")) {
        return generateName("shared");
      }

      if (module.userRequest.includes("components/flexComponents")) {
        return generateName("flexComponents");
      }

      return generateName("utility");
    },
    priority: 100,
    reuseExistingChunk: true,
    test: (module) => /[\/]components[\/](?=flexComponents|shared|utility)/.test(module.resource),
  },
  TravelersField: {
    name: "shared~TravelersField",
    priority: 100,
    reuseExistingChunk: true,
    test: (module) => /[\/]components[\/]shared[\/]TravelersField[\/]/.test(module.resource),
  },
  EgMapsBase: {
    enforce: true,
    name: "eg-maps-base-public",
    priority: 100,
    test: (module) => /([\/]node_modules[\/]@eg-maps[\/]base[\/]public)/.test(module.resource),
  },
  Egds: {
    enforce: true,
    name: "egds",
    priority: 100,
    test: (module) => /([\/]node_modules[\/]@egds(?![\/]tokens-web))/.test(module.resource),
  },
  Mobx: {
    enforce: true,
    name: "mobx",
    priority: 150,
    test: (module) => /([\/]node_modules[\/]mobx[\/]dist)/.test(module.resource),
  },
  EgDistances: {
    enforce: true,
    name: "eg-distances",
    priority: 100,
    test: (module) => /([\/]node_modules[\/]eg-distances[\/]public)/.test(module.resource),
  },
  UitkComponents: {
    minChunks: 1,

    minSize: 6000,
    name(module) {
      const generateName = (folder) => {
        const s = module.userRequest.split("/");
        const index = s.indexOf(folder);

        return s.slice(index, index + 2).join("~");
      };

      if (module.userRequest.includes("node_modules/uitk")) {
        return generateName("node_modules");
      }

      return generateName("utility");
    },
    priority: 100,
    reuseExistingChunk: true,
    test: (module) => /([\/]node_modules[\/](uitk-*))/.test(module.resource),
  },
  StoreWizardConfig: {
    enforce: true,
    name: "app-store-wizard-config",
    priority: 100,
    test: (module) => /[\/]stores[\/]wizard[\/]config/.test(module.resource),
  },
  StoreWizardState: {
    enforce: true,
    name: "app-store-wizard-state",
    priority: 100,
    test: (module) => /[\/]stores[\/]wizard[\/]state/.test(module.resource),
  },
  DThree: {
    enforce: true,
    name: "d3",
    priority: 100,
    test: (module) => /([\/]node_modules[\/](d3))/.test(module.resource),
  },
  EGDSCore: {
    name: "egds-core",
    enforce: true,
    priority: 90,
    test: /[\\/]node_modules[\\/](@egds\/react-core)(?!.*\.(css|scss)$)/,
  },
};
